import { type GridRowSelectionModel, type GridColDef } from '@mui/x-data-grid'
import {
  chain,
  concat,
  difference,
  flatten,
  get,
  isEmpty,
  isNil,
  compact,
  uniqBy,
} from 'lodash'
import { useMemo, useState } from 'react'
import { useLayout } from 'hooks/useLayout'
import { Page } from 'components/Shared/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Button, Divider, Typography } from '@mui/material'
import { useGetSweepstakeById } from 'hooks/api/useGetSweepstakeById'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import {
  dataTableAddressFormatter,
  dataTableLicenseNumberFormatter,
  pluralize,
} from 'utils/util'
import { CorporateAccountsTreeDataGrid } from 'components/CorporateAccountsTreeDataGrid/CorporateAccountsTreeDataGrid'
import { useGetLesWithCaAndOrg } from 'hooks/api/useGetLesWithCaAndOrg'
import { useSweepstakeParticipatingLocationsAndEnrollmentAgreement } from 'stores/useSweepstakeParticipatingLocationsAndEnrollmentAgreement'
import { HandleOnChangeSelection } from './HandleOnChangeSelection'
import {
  type Sweepstake,
  type CorporateAccount,
  type LicensedEstablishment,
  type Organization,
} from 'types/api'
import { SearchField } from 'components/SearchField'
import { useDebounce } from '@uidotdev/usehooks'
import { useGetAllCorporateAccounts } from 'hooks/api/useGetAllCorporateAccounts'
import { useGetAllLicensedEstablishments } from 'hooks/api/LicensedEstablishment/useGetAllLicensedEstablishments'
import { useGetAllOrganizations } from 'hooks/api/useGetAllOrganizations'

const DisplayCorporateAccountGrid = ({
  isSearchPending,
  licensedEstablishments,
  corporateAccounts,
  organizations,
  isMobile,
  selectedRowKeys,
  handleOnChange,
  enrolledLocationKeys,
}: {
  isSearchPending: boolean | undefined
  licensedEstablishments: LicensedEstablishment[]
  corporateAccounts: CorporateAccount[]
  organizations: Organization[]
  sweepstake: Sweepstake
  isMobile: boolean
  selectedRowKeys: string[]
  enrolledLocationKeys: string[]
  handleOnChange: (rowSelectionModel: GridRowSelectionModel) => void
}) => {
  if (isSearchPending) {
    return <ActivityIndicator />
  }

  if (!licensedEstablishments.length || !corporateAccounts.length) {
    return <Box>No results found</Box>
  }

  return (
    <Box>
      <CorporateAccountsTreeDataGrid
        recalculateRows={true}
        columns={defaultColumnsForEnrollment(isMobile)}
        corporateAccounts={corporateAccounts}
        organizations={organizations}
        licensedEstablishments={licensedEstablishments}
        checkboxSelection
        keepNonExistentRowsSelected
        rowSelectionModel={selectedRowKeys}
        onRowSelectionModelChange={handleOnChange}
        hideFooter={licensedEstablishments.length <= 20}
        initialState={{
          pagination: { paginationModel: { pageSize: 20 } },
        }}
        initialExpanded
        hideFooterSelectedRowCount
        pageSizeOptions={[20, 75, 100]}
        getRowClassName={(params) => {
          const id = Number(params.row.id)
          const isLicensedEstablishment =
            get(params.row, 'corporateAccount') !== undefined &&
            get(params.row, 'organization') !== undefined
          const isCorporateAccount = get(params.row, 'smartID') !== undefined
          const isOrganization =
            get(params.row, 'parentOrganization') !== undefined

          if (isLicensedEstablishment) {
            const isSelected =
              selectedRowKeys.find(
                (x) => x === `licensedEstablishment-${id}`
              ) !== undefined

            if (isSelected) {
              return 'selected'
            }
          } else if (isOrganization) {
            const isSelected =
              selectedRowKeys.find((x) => x === `organization-${id}`) !==
              undefined

            if (isSelected) {
              return 'selected'
            }
          } else if (isCorporateAccount) {
            const isSelected =
              selectedRowKeys.find((x) => x === `corporateAccount-${id}`) !==
              undefined

            if (isSelected) {
              return 'selected'
            }
          }

          return ''
        }}
        isRowSelectable={(params) => {
          const id = Number(params.row.id)
          const isLicensedEstablishment =
            get(params.row, 'corporateAccount') !== undefined &&
            get(params.row, 'organization') !== undefined
          const isCorporateAccount = get(params.row, 'smartID') !== undefined
          const isOrganization =
            get(params.row, 'parentOrganization') !== undefined

          if (isLicensedEstablishment) {
            return (
              enrolledLocationKeys.find(
                (x) => Number(x.split('-')[1]) === id
              ) === undefined
            )
          }

          if (isCorporateAccount) {
            const lesWithCa = licensedEstablishments
              .filter((x) => x.corporateAccountId === id)
              .map((x) => `licensedEstablishment-${x.id}`)

            if (difference(lesWithCa, enrolledLocationKeys).length === 0) {
              return false
            }
          }

          if (isOrganization) {
            const lesWithOrg = licensedEstablishments
              .filter((x) => x.organizationId === id)
              .map((x) => `licensedEstablishment-${x.id}`)

            if (difference(lesWithOrg, enrolledLocationKeys).length === 0) {
              return false
            }
          }
          return true
        }}
      />
    </Box>
  )
}

export const defaultColumnsForEnrollment = (
  isMobile: boolean
): GridColDef[] => {
  return [
    {
      field: 'name',
      headerName: 'Account Name',
      flex: 1,
      renderCell: (params) => {
        const isLicensedEstablishment =
          get(params.row, 'corporateAccount') !== undefined &&
          get(params.row, 'organization') !== undefined

        const displayName = isLicensedEstablishment
          ? get(params.row, 'standardName', '')
          : get(params.row, 'name')

        return <p>{displayName}</p>
      },
    },
    {
      field: 'licenseNumber',
      headerName: 'License Number',
      flex: 0.5,
      valueFormatter: dataTableLicenseNumberFormatter(isMobile),
      renderCell: (params) => {
        const isALicensedEstablishment =
          get(params.row, 'corporateAccount') !== undefined &&
          get(params.row, 'organization') !== undefined

        return (
          <p>
            {isEmpty(params.formattedValue) && !isALicensedEstablishment
              ? '-'
              : `#${String(params.formattedValue)}`}
          </p>
        )
      },
    },
    {
      field: 'addresses',
      headerName: 'Address',
      flex: 1,
      valueFormatter: dataTableAddressFormatter(true),
      renderCell: (params) => {
        const isALicensedEstablishment =
          get(params.row, 'corporateAccount') !== undefined &&
          get(params.row, 'organization') !== undefined

        return (
          <p className="overflow-hidden whitespace-nowrap text-ellipsis	">
            {params.formattedValue === 'Unspecified Address' &&
            !isALicensedEstablishment
              ? '-'
              : params.formattedValue}
          </p>
        )
      },
    },
  ]
}

const Content = ({
  sweepstake,
  licensedEstablishments,
  organizations,
  corporateAccounts,
  setSearchKey,
  searchKey,
}: {
  sweepstake: Sweepstake
  licensedEstablishments: LicensedEstablishment[]
  organizations: Organization[]
  corporateAccounts: CorporateAccount[]
  setSearchKey: (key: string) => void
  searchKey: string
}) => {
  const { isMobile } = useLayout()
  const navigate = useNavigate()
  const [isSearchPending, setIsSearchPending] = useState(false)

  const { setParticipatingLocations, participatingLocations } =
    useSweepstakeParticipatingLocationsAndEnrollmentAgreement()

  const selectedLocationKeys = useMemo(() => {
    if (isNil(participatingLocations)) {
      return []
    }

    return participatingLocations.map((leId) => `licensedEstablishment-${leId}`)
  }, [participatingLocations])

  const enrolledLocationKeys = useMemo(() => {
    if (isNil(sweepstake.licensedEstablishments)) {
      throw Error('No sweepstake data')
    }

    return sweepstake.licensedEstablishments.map(
      (le) => `licensedEstablishment-${le?.id}`
    )
  }, [sweepstake])

  const selectedOrEnrolledLocationKeys = useMemo(
    () => concat(enrolledLocationKeys, selectedLocationKeys),
    [enrolledLocationKeys, selectedLocationKeys]
  )

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>(
    new HandleOnChangeSelection({
      previousRows: [],
      rows: selectedOrEnrolledLocationKeys,
      licensedEstablishments,
      enrolledLicensedEstablishmentIds:
        sweepstake.licensedEstablishments?.map((x) => x.id) ?? [],
      organizations,
      corporateAccounts,
      selectedRows: selectedOrEnrolledLocationKeys,
    }).getInitialSelection()
  )
  const selectedLeCount = useMemo(() => {
    return difference(selectedRowKeys, enrolledLocationKeys).filter(
      (x) => x.split('-')[0] === 'licensedEstablishment'
    ).length
  }, [selectedRowKeys, enrolledLocationKeys])

  const handleOnChange = (rowSelectionModel: GridRowSelectionModel) => {
    setSelectedRowKeys((prev) =>
      new HandleOnChangeSelection({
        previousRows: prev,
        rows: concat(
          flatten(rowSelectionModel.map((x) => String(x))),
          enrolledLocationKeys
        ),
        licensedEstablishments,
        enrolledLicensedEstablishmentIds:
          sweepstake.licensedEstablishments?.map((x) => x.id) ?? [],
        organizations,
        corporateAccounts,
        selectedRows: concat(selectedRowKeys, enrolledLocationKeys),
      }).handleCorporateAccountAndOrgSelection()
    )
  }

  const handleOnSave = () => {
    const justLeLocations = chain(selectedRowKeys)
      .map((x) => {
        const base = x.split('-')
        const type = base[0]
        const id = Number(base[1])
        if (type === 'licensedEstablishment') {
          return id
        }
        return null
      })
      .compact()
      .value()

    setParticipatingLocations(justLeLocations)
    navigate(
      `/Contests/Sweepstakes/${sweepstake?.id}/AdditionalEnrollmentDetails`
    )
  }

  let timeoutId: NodeJS.Timeout
  const searchPendingHandler = () => {
    clearTimeout(timeoutId)
    setIsSearchPending(true)
    timeoutId = setTimeout(() => setIsSearchPending(false), 1500)
  }

  return (
    <Page
      header={
        <>
          <PageHeader
            title="Enroll Locations"
            isSecondary={true}
            backText={`${sweepstake.name}`}
            backPath={`/Contests/Sweepstakes/${sweepstake.id}/ParticipatingLocations`}
          >
            <Typography
              variant="body-1"
              className="text-secondary"
              paddingTop="12px"
              paddingBottom="8px"
            >
              Select which locations you want to enroll in {sweepstake.name}.
              Please note that you cannot unenroll locations from this table.
            </Typography>
          </PageHeader>
          <Divider />
        </>
      }
      footer={
        <Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            columnGap={2}
          >
            <Box width={'100%'} alignContent={'center'}>
              <Typography variant="body-1" color={'text.secondary'}>
                {pluralize(selectedLeCount, 'Licensed Establishment')} Selected
                to Enroll in Sweepstakes
              </Typography>
            </Box>
            <Button
              variant="text"
              onClick={() => {
                setParticipatingLocations(undefined)
                if (isNil(sweepstake)) {
                  throw Error("Sweepstake data doesn't exist")
                }
                navigate(
                  `/Contests/Sweepstakes/${sweepstake.id}/ParticipatingLocations`
                )
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={selectedLeCount === 0}
              onClick={() => handleOnSave()}
            >
              Next
            </Button>
          </Box>
        </Box>
      }
    >
      <SearchField
        placeholder="Search"
        onChange={(e) => {
          searchPendingHandler()
          setSearchKey(e.target.value)
        }}
        sx={{ paddingBottom: 3, width: '520px' }}
        fullWidth={!isMobile}
      />
      <DisplayCorporateAccountGrid
        corporateAccounts={corporateAccounts}
        organizations={organizations}
        licensedEstablishments={licensedEstablishments}
        enrolledLocationKeys={enrolledLocationKeys}
        handleOnChange={handleOnChange}
        isMobile={isMobile}
        isSearchPending={
          (!isEmpty(searchKey) && searchKey.length < 3) || isSearchPending
        }
        selectedRowKeys={selectedRowKeys}
        sweepstake={sweepstake}
      />
    </Page>
  )
}

export const ManageEnrollLocationsPage = () => {
  const { id: idParam } = useParams()
  const sweepstakeId = Number(idParam)
  const sweepstakeQuery = useGetSweepstakeById({ sweepstakeId })
  const [searchKey, setSearchKey] = useState('')
  const debouncedSearch = useDebounce(searchKey, 500)

  const {
    isPending: getAllPending,
    isError: getAllError,
    licensedEstablishments,
    organizations,
    corporateAccounts,
  } = useGetLesWithCaAndOrg({
    searchKey: debouncedSearch,
  })

  const { data: allLicensedEstablishments } = useGetAllLicensedEstablishments()
  const { data: allCorporateAccounts } = useGetAllCorporateAccounts()
  const { data: allOrganizations } = useGetAllOrganizations()
  const filteredCorporateAccounts = useMemo(() => {
    return allCorporateAccounts?.filter(
      (ca) => ca.name?.toLowerCase().includes(searchKey.toLowerCase())
    )
  }, [allCorporateAccounts, searchKey])
  const filteredCANames = useMemo(() => {
    return filteredCorporateAccounts?.flatMap((item) => item.name)
  }, [filteredCorporateAccounts])
  const licensedEstablishmentsFromCAs = useMemo(() => {
    return allLicensedEstablishments?.filter(
      (le) =>
        filteredCANames?.includes(le.corporateAccount?.name) &&
        le.active &&
        !le.excludedFromSweepstakes
    )
  }, [allLicensedEstablishments, filteredCANames])

  const orgsFromCAs = useMemo(() => {
    return allOrganizations?.filter(
      (org) => filteredCANames?.includes(org.corporateAccount?.name)
    )
  }, [allOrganizations, filteredCANames])
  const filteredOrganizations = useMemo(() => {
    return allOrganizations?.filter(
      (org) => org.name?.toLowerCase().includes(searchKey.toLowerCase())
    )
  }, [allOrganizations, searchKey])
  const filteredOrgNames = useMemo(() => {
    return filteredOrganizations?.flatMap((item) => item.name)
  }, [filteredOrganizations])

  // recursively search through organizations' `parentOrganization`
  const findMatchingOrganization = (le: any): boolean => {
    if (!le?.organization) {
      return false
    }

    if (filteredOrgNames?.includes(le.organization.name)) {
      return true
    }

    return findMatchingOrganization(le.organization.parentOrganization)
  }
  const licensedEstablishmentsFromOrgs = useMemo(() => {
    return allLicensedEstablishments?.filter(
      (le) =>
        findMatchingOrganization(le) && le.active && !le.excludedFromSweepstakes
    )
  }, [allLicensedEstablishments])

  const availableLicensedEstablishments = useMemo(() => {
    return uniqBy(
      compact(
        concat(
          sweepstakeQuery.data?.licensedEstablishments?.filter(
            (le) => le.active && !le.excludedFromSweepstakes
          ),
          licensedEstablishments,
          licensedEstablishmentsFromCAs,
          licensedEstablishmentsFromOrgs
        )
      ),
      'id'
    ).filter((le) => le.active && !le.excludedFromSweepstakes)
  }, [
    licensedEstablishments,
    sweepstakeQuery,
    licensedEstablishmentsFromCAs,
    licensedEstablishmentsFromOrgs,
  ])

  const availableCorporateAccounts = useMemo(() => {
    return uniqBy(
      compact(
        concat(
          corporateAccounts,
          filteredCorporateAccounts,
          filteredOrganizations?.flatMap((org) => org.corporateAccount)
        )
      ),
      'id'
    ).filter((ca) =>
      compact(
        availableLicensedEstablishments.map((x) => x.corporateAccountId)
      ).includes(ca.id)
    )
  }, [
    corporateAccounts,
    filteredCorporateAccounts,
    filteredOrganizations,
    availableLicensedEstablishments,
  ])

  const availableOrganizations = useMemo(() => {
    return uniqBy(
      compact(concat(organizations, filteredOrganizations, orgsFromCAs)),
      'id'
    ).filter((org) =>
      compact(
        availableLicensedEstablishments.map((x) => x.organizationId)
      ).includes(org.id)
    )
  }, [
    organizations,
    filteredOrganizations,
    orgsFromCAs,
    availableLicensedEstablishments,
  ])

  if (
    sweepstakeQuery.isPending ||
    getAllPending ||
    isNil(sweepstakeQuery.data)
  ) {
    return <ActivityIndicator />
  }

  if (sweepstakeQuery.isError) {
    return <Box>An error occurred fetching the sweepstake.</Box>
  }

  if (getAllError) {
    return (
      <Box>
        An error occurred fetching the licensed Establishments/corporate
        accounts/organizations.
      </Box>
    )
  }
  return (
    <Content
      sweepstake={sweepstakeQuery.data}
      licensedEstablishments={availableLicensedEstablishments}
      organizations={availableOrganizations}
      corporateAccounts={availableCorporateAccounts}
      setSearchKey={(key) => setSearchKey(key)}
      searchKey={searchKey}
    />
  )
}
