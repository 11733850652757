import { Button, Typography } from '@mui/material'
import { ActivityButton } from 'components/ActivityButton'
import { Modal } from 'components/Modal/Modal'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { colors } from 'components/ThemeProvider'
import { useLayout } from 'hooks/useLayout'
import { type LicensedEstablishment } from 'types/api'

export const EditLicensedEstablishmentExcludedStatusModal = ({
  licensedEstablishment,
  isModalOpen,
  updateIsActive,
  toggleModalIsOpen,
  onSubmitLicensedEstablishment,
}: {
  licensedEstablishment: LicensedEstablishment
  isModalOpen: boolean
  updateIsActive: boolean
  toggleModalIsOpen: () => void
  onSubmitLicensedEstablishment: (
    licensedEstablishment: LicensedEstablishment
  ) => void
}) => {
  const { isMobile } = useLayout()
  const heightModal = isMobile ? '100%' : 'auto'

  const onChangeStatus = () => {
    // Include/Exclude LE
    onSubmitLicensedEstablishment({
      ...licensedEstablishment,
      excludedFromSweepstakes: !licensedEstablishment.excludedFromSweepstakes,
    })
  }

  return (
    <Modal
      isOpen={isModalOpen}
      toggleIsOpen={toggleModalIsOpen}
      sx={{
        width: isMobile ? '100%' : '60%',
        height: 'auto',
      }}
    >
      <ModalFullHeader
        toggleIsOpen={toggleModalIsOpen}
        title={`${
          licensedEstablishment.excludedFromSweepstakes
            ? 'Include Licensed Establishment in Sweepstakes'
            : 'Exclude Licensed Establishment from Sweepstakes'
        }`}
        subTitle={`Name: ${licensedEstablishment.standardName}`}
        subTitle2={`ID: ${licensedEstablishment.id}`}
      />
      <ModalFullBody heightModal={heightModal}>
        <div className="flex flex-col text-center py-8">
          <Typography variant="h4">
            {`Are you sure you want to ${
              licensedEstablishment.excludedFromSweepstakes
                ? 'include'
                : 'exclude'
            } the Licensed Establishment for ${
              licensedEstablishment.standardName
            }?`}
          </Typography>
          <Typography
            variant="body-1"
            color={colors.text.secondary}
            marginTop={2}
          >
            {`Doing this will ${
              licensedEstablishment.excludedFromSweepstakes
                ? 'include the Licensed Establishment in all Sweepstakes.'
                : 'exclude the Licensed Establishment from all Sweepstakes.'
            } `}
          </Typography>
        </div>
      </ModalFullBody>
      <ModalFullFooter>
        <Button onClick={toggleModalIsOpen}>Cancel</Button>
        <ActivityButton
          active={updateIsActive}
          type="submit"
          variant="contained"
          onClick={onChangeStatus}
        >
          {licensedEstablishment.excludedFromSweepstakes
            ? 'Include in Sweepstakes'
            : 'Exclude from Sweepstakes'}
        </ActivityButton>
      </ModalFullFooter>
    </Modal>
  )
}
