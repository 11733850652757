import Box from '@mui/material/Box'
import { BusinessDetailsDesktop } from './BusinessDetails/BusinessDetailsDesktop'
import { ContactDetailsDesktop } from './ContactDetails/ContactDetailsDesktop'
import { AddressDetailsDesktop } from './AddressDetails/AddressDetailsDesktop'
import { LinksDetailsDesktop } from './LinksDetails/LinksDetailsDesktop'
import { EditLicensedEstablishmentBusinessDetails } from 'components/LicensedEstablishmentDetails/BusinessDetails/EditLicensedEstablishmentBusinessDetails'
import { ScheduleDetails } from './ScheduleDetails/ScheduleDetails'
import { type LicensedEstablishment } from 'types/api'
import { useState } from 'react'
import { usePutLicensedEstablishment } from 'hooks/api/LicensedEstablishment/usePutLicensedEstablishment'
import { type RequiredId } from 'utils/typeUtils'
import { useSnackbar } from 'stores/useSnackbar'
import { EditLicensedEstablishmentContactDetails } from './ContactDetails/EditLicensedEstablishmentContactDetails'
import { EditLicensedEstablishmentAddressDetails } from './AddressDetails/EditLicensedEstablishmentAddressDetails'
import { EditLicensedEstablishmentLinksDetails } from './LinksDetails/EditLicensedEstablishmentLinksDetails'
import { RelatedAccountsDesktop } from './RelatedAccounts/RelatedAccountsDesktop'
import { ThumbnailDetails } from './ThumbnailDetails/ThumbnailDetails'
import { Button, Typography } from '@mui/material'
import { EditLicensedEstablishmentActiveStatus } from './ActiveStatus/EditLicensedEstablishmentActiveStatus'
import { useGetRewardProgramByLicensedEstablishmentId } from 'hooks/api/useGetRewardProgramByLicensedEstablishmentId'
import { useNavigate } from 'react-router-dom'
import { EditLicensedEstablishmentExcludedStatusModal } from './ExcludeFromSweepstakes/EditLicensedEstablishmentExcludedStatusModal'
import { SectionHeader } from 'components/SectionHeader'

interface LicensedEstablishmentDetailsProps {
  licensedEstablishment: RequiredId<LicensedEstablishment>
}

export const LicensedEstablishmentDetailsDesktop = ({
  licensedEstablishment,
}: LicensedEstablishmentDetailsProps) => {
  const setMessage = useSnackbar((state) => state.setMessage)
  const navigate = useNavigate()

  const [isBusinessDetailModalOpen, setIsBusinessDetailModalOpen] =
    useState(false)
  const [isContactDetailModalOpen, setIsContactDetailModalOpen] =
    useState(false)
  const [isAddressDetailModalOpen, setIsAddressDetailModalOpen] =
    useState(false)
  const [isLinksDetailModalOpen, setIsLinksDetailModalOpen] = useState(false)
  const [isExcludeModalOpen, setIsExcludeModalOpen] = useState(false)
  const [isDeactivateModalOpen, setIsDeactivateModalOpen] = useState(false)

  const toggleBusinessDetailModalIsOpen = async () =>
    setIsBusinessDetailModalOpen(!isBusinessDetailModalOpen)
  const toggleContactDetailModalIsOpen = async () =>
    setIsContactDetailModalOpen(!isContactDetailModalOpen)
  const toggleAddressDetailModalIsOpen = async () =>
    setIsAddressDetailModalOpen(!isAddressDetailModalOpen)
  const toggleLinksDetailModalIsOpen = async () =>
    setIsLinksDetailModalOpen(!isLinksDetailModalOpen)
  const toggleExcludeModalIsOpen = () =>
    setIsExcludeModalOpen(!isExcludeModalOpen)
  const toggleDeactivateModalIsOpen = () =>
    setIsDeactivateModalOpen(!isDeactivateModalOpen)

  const closeModalOpened = () => {
    setIsBusinessDetailModalOpen(
      isBusinessDetailModalOpen ? false : isBusinessDetailModalOpen
    )
    setIsContactDetailModalOpen(
      isContactDetailModalOpen ? false : isContactDetailModalOpen
    )
    setIsAddressDetailModalOpen(
      isAddressDetailModalOpen ? false : isAddressDetailModalOpen
    )
    setIsLinksDetailModalOpen(
      isLinksDetailModalOpen ? false : isLinksDetailModalOpen
    )
    setIsExcludeModalOpen(isExcludeModalOpen ? false : isExcludeModalOpen)
    setIsDeactivateModalOpen(
      isDeactivateModalOpen ? false : isDeactivateModalOpen
    )
  }

  const putLicensedEstablishmentMutation = usePutLicensedEstablishment({
    licensedEstablishmentId: licensedEstablishment.id,
    onSuccess: () => {
      setMessage('Licensed Establishment has been updated.', 'success', {
        vertical: 'top',
        horizontal: 'right',
      })
      closeModalOpened()
    },
    onError: () => {
      setMessage('Error updating Licensed Establishment', 'error', {
        vertical: 'top',
        horizontal: 'right',
      })
    },
  })

  const putLicensedEstablishmentMutationStatus = usePutLicensedEstablishment({
    licensedEstablishmentId: licensedEstablishment.id,
    onSuccess: () => {
      setMessage(
        `${licensedEstablishment.standardName} successfully updated`,
        'success'
      )
      closeModalOpened()
      navigate('/ClientManagement/LicensedEstablishments')
    },
    onError: () => {
      setMessage('Error updating Licensed Establishment', 'error')
    },
  })

  const RewardProgramQuery = useGetRewardProgramByLicensedEstablishmentId({
    licensedEstablishmentId: licensedEstablishment.id,
    includeGlobal: true,
  })
  const locationRewardsPrograms = (RewardProgramQuery.data ?? []).filter(
    (rp) => rp.licensedEstablishmentId === licensedEstablishment.id
  )

  return (
    <>
      <Box className="gap-12 flex flex-col">
        <div hidden={!licensedEstablishment.excludedFromSweepstakes}>
          <SectionHeader
            showButton={false}
            title="Licensed Establishment Excluded"
          />
          <div className="flex flex-col border rounded p-6">
            <div>
              <Typography variant="body-1">
                <p>
                  This Licensed Establishment is excluded from all Sweepstakes
                </p>
              </Typography>
            </div>
          </div>
        </div>

        <Box>
          <BusinessDetailsDesktop
            licensedEstablishment={licensedEstablishment}
            onClickEdit={toggleBusinessDetailModalIsOpen}
          />
        </Box>

        <Box>
          <RelatedAccountsDesktop
            corporateAccountName={String(
              licensedEstablishment.corporateAccount?.name
            )}
            organizationName={licensedEstablishment.organization?.name}
            leId={licensedEstablishment.id}
          />
        </Box>

        <Box>
          <ContactDetailsDesktop
            licensedEstablishment={licensedEstablishment}
            onClickEdit={toggleContactDetailModalIsOpen}
          ></ContactDetailsDesktop>
        </Box>

        <Box>
          <AddressDetailsDesktop
            licensedEstablishment={licensedEstablishment}
            onClickEdit={() => setIsAddressDetailModalOpen(true)}
          />
        </Box>

        <Box>
          <ScheduleDetails licensedEstablishment={licensedEstablishment} />
        </Box>

        <Box>
          <ThumbnailDetails
            licensedEstablishment={licensedEstablishment}
          ></ThumbnailDetails>
        </Box>

        <Box>
          <LinksDetailsDesktop
            licensedEstablishment={licensedEstablishment}
            onClickEdit={() => setIsLinksDetailModalOpen(true)}
          />
        </Box>
        <Box>
          <div>
            <Button
              sx={{
                padding: '0 16 0 16',
              }}
              onClick={() => {
                setIsExcludeModalOpen(!isExcludeModalOpen)
              }}
            >
              {licensedEstablishment.excludedFromSweepstakes
                ? 'Include Licensed Establishment in Sweepstakes'
                : 'Exclude Licensed Establishment from Sweepstakes'}
            </Button>
          </div>
          <Button
            variant="text"
            sx={{
              padding: '0 16 0 16',
            }}
            onClick={toggleDeactivateModalIsOpen}
          >
            {licensedEstablishment.active
              ? 'Deactivate Licensed Establishment'
              : 'Reactivate Licensed Establishment'}
          </Button>
        </Box>
      </Box>

      <EditLicensedEstablishmentBusinessDetails
        licensedEstablishment={licensedEstablishment}
        updateIsActive={putLicensedEstablishmentMutation.isPending}
        isModalOpen={isBusinessDetailModalOpen}
        toggleModalIsOpen={toggleBusinessDetailModalIsOpen}
        onSubmitLicensedEstablishment={(licensedEstablishment) => {
          putLicensedEstablishmentMutation.mutate(licensedEstablishment)
        }}
      />

      <EditLicensedEstablishmentContactDetails
        licensedEstablishment={licensedEstablishment}
        updateIsActive={putLicensedEstablishmentMutation.isPending}
        isModalOpen={isContactDetailModalOpen}
        toggleModalIsOpen={toggleContactDetailModalIsOpen}
        onSubmitLicensedEstablishment={(licensedEstablishment) => {
          putLicensedEstablishmentMutation.mutate(licensedEstablishment)
        }}
      />

      <EditLicensedEstablishmentAddressDetails
        licensedEstablishment={licensedEstablishment}
        updateIsActive={putLicensedEstablishmentMutation.isPending}
        isModalOpen={isAddressDetailModalOpen}
        toggleModalIsOpen={toggleAddressDetailModalIsOpen}
        onSubmitLicensedEstablishment={(licensedEstablishment) => {
          putLicensedEstablishmentMutation.mutate(licensedEstablishment)
        }}
      />

      <EditLicensedEstablishmentLinksDetails
        licensedEstablishment={licensedEstablishment}
        updateIsActive={putLicensedEstablishmentMutation.isPending}
        isModalOpen={isLinksDetailModalOpen}
        toggleModalIsOpen={toggleLinksDetailModalIsOpen}
        onSubmitLicensedEstablishment={(licensedEstablishment) => {
          putLicensedEstablishmentMutation.mutate(licensedEstablishment)
        }}
      />

      <EditLicensedEstablishmentExcludedStatusModal
        licensedEstablishment={licensedEstablishment}
        updateIsActive={putLicensedEstablishmentMutationStatus.isPending}
        isModalOpen={isExcludeModalOpen}
        toggleModalIsOpen={toggleExcludeModalIsOpen}
        onSubmitLicensedEstablishment={(licensedEstablishment) => {
          putLicensedEstablishmentMutationStatus.mutate(licensedEstablishment)
        }}
      />

      <EditLicensedEstablishmentActiveStatus
        licensedEstablishment={licensedEstablishment}
        updateIsActive={putLicensedEstablishmentMutationStatus.isPending}
        locationRewardsPrograms={locationRewardsPrograms}
        isModalOpen={isDeactivateModalOpen}
        toggleModalIsOpen={toggleDeactivateModalIsOpen}
        onSubmitLicensedEstablishment={(licensedEstablishment) => {
          putLicensedEstablishmentMutationStatus.mutate(licensedEstablishment)
        }}
      />
    </>
  )
}
