import { Box, Button, Typography } from '@mui/material'
import { SweepstakesDetailsHeader } from '../SweepstakesDetailsPage/SweepstakeDetailsHeader'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetSweepstakeById } from 'hooks/api/useGetSweepstakeById'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import _, { isNil } from 'lodash'
import { DataTable } from 'components/Shared/DataTable'
import noLocationFound from 'assets/locationNotFound.svg'
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  type GridRowParams,
} from '@mui/x-data-grid'
import { useEffect, useMemo, useState } from 'react'
import { SweepstakesConfirmationModal } from './SweepstakesConfirmationModal'
import { useDrawSweepstakeWinners } from 'hooks/api/Sweepstake/useDrawSweepstakeWinners'
import { ActivityButton } from 'components/ActivityButton'
import {
  FilterStatusList,
  statusTextMap,
} from 'components/List/FilterStatusList'
import Close from '@mui/icons-material/Close'
import { SweepstakesWinnerActionsMenu } from 'components/Sweepstakes/SweepstakesWinnerActionsMenu'
import { NoResultsOverlayCustom } from 'components/DataTable/NoResultsOverlayCustom'
import { type SweepstakeWinnerStatusType } from 'types/api'
import { useNotifyLocations } from 'hooks/api/Sweepstake/useNotifyLocations'
import { SweepstakesRequestRedrawModal } from './SweepstakesRequestRedrawModal'
import { useRedrawWinner } from 'hooks/api/Sweepstake/useRedrawWinner'
import { useSnackbar } from 'stores/useSnackbar'
import { getGrandPrizeWinnersColumns } from './GrandPrizeWinnersColumns'
import { getLocationWinnersColumns } from './LocationWinnersColumns'
import { type components } from 'api/playerPayback/api'
import { SweepstakesViewRedrawRequestModal } from './SweepstakesViewRedrawRequestModal'
import { useDenyLocationRedrawRequest } from 'hooks/api/Sweepstake/useDenyLocationRedrawRequest'
import { useRequestRedrawModal } from 'stores/useRequestRedrawModal'

export interface SelectedWinnerType {
  locationName: string
  licenseNumber: string
  winnerName: string
  sweepstakesWinner: components['schemas']['FullSweepstakeWinnerDTO']
  drawingSequence: number
}

export interface GrandPrizeWinner {
  id: string
  winnerName: string
  patronId: number
  tierName: string
  winnerStatus?: SweepstakeWinnerStatusType
  sweepstakeDrawingId: number
  contestEntryId: number
  drawnDate: string
  drawingSequence: number
}

export const SweepstakesWinnersPage = () => {
  const { id: idParam } = useParams()
  const navigate = useNavigate()
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)
  const sweepstakeQuery = useGetSweepstakeById({
    sweepstakeId: Number(idParam),
  })
  // Mutations
  const drawSweepstakesWinnersMutation = useDrawSweepstakeWinners()
  const notifyLocationsMutation = useNotifyLocations()
  const redrawWinnerMutation = useRedrawWinner({
    onSuccess: () => {
      setSnackbarMessage('Winner redrawn successfully', 'success')
    },
    onError: () => {
      setSnackbarMessage('Error redrawing winner', 'error')
    },
  })
  const denyLocationRedrawRequest = useDenyLocationRedrawRequest({
    onSuccess: () => {
      setSnackbarMessage('Redraw request denied', 'success')
    },
    onError: () => {
      setSnackbarMessage('Error denying redraw request', 'error')
    },
  })
  // Redraw Winners Modal(this is the temp modal to redraw via button, will be removed)
  const [isRedrawWinnersModalOpen, setIsRedrawWinnersModalOpen] =
    useState(false)
  const toggleRedrawWinnersModalIsOpen = () =>
    setIsRedrawWinnersModalOpen(!isRedrawWinnersModalOpen)
  // Notify Winners Modal
  const [isNotifyWinnersModalOpen, setIsNotifyWinnersModalOpen] =
    useState(false)
  const toggleNotifyWinnersModalIsOpen = () =>
    setIsNotifyWinnersModalOpen(!isNotifyWinnersModalOpen)
  // Request New Winners Modal
  const { setData: setIsIndividualRedrawModalOpen } = useRequestRedrawModal()

  // View Request New Winners Modal
  const [isViewRequestRedrawModalOpen, setIsViewRequestRedrawModalOpen] =
    useState(false)
  const toggleViewRequestRedrawModalIsOpen = () =>
    setIsViewRequestRedrawModalOpen(!isViewRequestRedrawModalOpen)

  const [winnerStatusFilter, setWinnerStatusFilter] =
    useState<SweepstakeWinnerStatusType | null>(null)
  const [selectedWinnerRows, setSelectedWinnerRows] = useState<
    SelectedWinnerType[]
  >([])
  const removeWinnerStatusFilter = () => {
    setWinnerStatusFilter(null)
    setSelectedWinnerRows([])
  }

  const handleSelectAllWinnerRows = () => {
    const filteredRows = locationWinners.filter(
      (row) => row.winnerStatus === winnerStatusFilter
    )

    if (selectedWinnerRows.length === filteredRows.length) {
      setSelectedWinnerRows([])
    } else {
      setSelectedWinnerRows(
        filteredRows.map((row) => {
          return {
            locationName: row.leName,
            licenseNumber: row.leLicenseNumber,
            winnerName: row.winnerName,
            sweepstakesWinner: row.dto,
            drawingSequence: row.drawingSequence ?? 0,
          }
        })
      )
    }
  }

  const handleCheckboxChange = (selectedWinner: SelectedWinnerType) => {
    setSelectedWinnerRows((prev) => {
      const hasWinner = !isNil(
        selectedWinnerRows.find(
          (winner) =>
            winner.sweepstakesWinner.id === selectedWinner.sweepstakesWinner.id
        )
      )
      if (hasWinner) {
        return prev.filter(
          (winner) =>
            winner.sweepstakesWinner.id !== selectedWinner.sweepstakesWinner.id
        )
      } else {
        return [...prev, selectedWinner]
      }
    })
  }

  const handleRowClick = (params: GridRowParams) => {
    const clickedRow = params.row
    if (clickedRow.dto) {
      setSelectedWinner({
        locationName: clickedRow.leName,
        licenseNumber: clickedRow.leLicenseNumber,
        winnerName: clickedRow.winnerName,
        sweepstakesWinner: clickedRow.dto,
        drawingSequence: clickedRow.drawingSequence,
      })
    }
    if (clickedRow.winnerName !== '-') {
      navigate(`/Winners/${params.id}`)
    }
  }

  const drawWinners = () => {
    drawSweepstakesWinnersMutation.mutate({ id: sweepstake.id })
  }

  const notifyLocations = () => {
    notifyLocationsMutation.mutate({ contestId: sweepstake.id })
  }

  const redrawWinners = (
    theReason: string,
    sweepstakeDrawingId: number,
    sweepstakeWinnerId?: number
  ) => {
    redrawWinnerMutation.mutate({
      sweepstakeDrawingId,
      redrawReason: theReason,
      sweepstakeWinnerId,
    })
  }

  const denyRedrawRequest = () => {
    if (selectedWinner?.sweepstakesWinner) {
      denyLocationRedrawRequest.mutate({
        sweepstakeDrawingId:
          selectedWinner.sweepstakesWinner.sweepstakeDrawingId,
      })
    }
  }

  const [selectedWinner, setSelectedWinner] = useState<SelectedWinnerType>()

  const { locationDrawings, grandPrizeWinners, sweepstakeName } =
    useMemo(() => {
      const [allGrandPrizeDrawings, allLocationDrawings] = _.partition(
        sweepstakeQuery.data?.sweepstakeDrawings ?? [],
        (d) => d.type === 'GrandPrize'
      )

      //  Get the last 4 grand prize drawings
      //  There is one Sweepstake Drawing per Tier, and this ensures we only get
      //  the most recent drawing for each tier
      const grandPrizeDrawings = allGrandPrizeDrawings.slice(-4)

      // Use a Map to keep track of the most recent drawing for each licensedEstablishmentId
      const locationDrawingsByEstablishment = new Map<
        number,
        (typeof allLocationDrawings)[0]
      >()

      allLocationDrawings.forEach((ld) => {
        const establishmentId = ld.licensedEstablishment?.id
        if (establishmentId != null) {
          // Always set the latest occurrence of the location drawing
          locationDrawingsByEstablishment.set(establishmentId, ld)
        }
      })

      // Extract the values from the map, which are the most recent drawings for each establishment
      const locationDrawings = Array.from(
        locationDrawingsByEstablishment.values()
      )

      const grandPrizeWinners: GrandPrizeWinner[] = []
      grandPrizeDrawings.forEach((drawing) => {
        drawing.sweepstakeWinners
          ?.filter((winner) => winner.resultType === 'Winner')
          .forEach((winner) => {
            grandPrizeWinners.push({
              id: String(winner.id),
              winnerName: `${winner.patron?.firstName} ${winner.patron?.lastName}`,
              patronId: winner.patronId,
              tierName:
                winner.patron?.currentTier?.tier?.type ??
                winner.patronTier?.tier?.type ??
                'Member',
              winnerStatus: winner.status,
              sweepstakeDrawingId: winner.sweepstakeDrawingId,
              contestEntryId: winner.contestEntryId,
              drawnDate: winner.dateDrawn ?? '-',
              drawingSequence: winner.drawingSequence ?? 0,
            })
          })
      })

      const sweepstakeName = sweepstakeQuery.data?.name

      return {
        locationDrawings,
        grandPrizeWinners,
        sweepstakeName,
      }
    }, [sweepstakeQuery.data])

  const locationWinners = useMemo(() => {
    return locationDrawings
      .flatMap((ld) => {
        const winners =
          ld.sweepstakeWinners?.map((winner) => {
            const fullName = `${winner.patron?.firstName} ${winner.patron?.lastName}`
            const validWinnerName = fullName.trim() !== ''
            return {
              id: winner.id,
              leName: ld.licensedEstablishment?.standardName ?? '???',
              leLicenseNumber: validWinnerName
                ? `#${ld.licensedEstablishment?.licenseNumber}`
                : '-',
              winnerName: validWinnerName ? fullName : '-',
              patronId: winner.patronId,
              winnerStatus: winner.status ?? 'NoWinner',
              dto: winner,
              resultType: winner.resultType,
              drawingSequence: winner.drawingSequence,
              drawnDate: winner.dateDrawn ?? '-',
            }
          }) ?? []

        const allIneligible = winners.every(
          (winner) => winner.resultType === 'Ineligible'
        )
        if (allIneligible && winners.length > 0) {
          return [
            {
              ...winners[0],
              winnerName: '-',
              winnerStatus: 'NoWinner',
            },
          ]
        }
        return winners
      })
      .filter(
        (winner) =>
          winner.resultType === 'Winner' || winner.winnerStatus === 'NoWinner'
      )
      .sort((a, b) => {
        const priority = [
          'RedrawRequest',
          'WinnerValidation',
          'AffidavitReceived',
        ]

        const aIndex = priority.indexOf(a.winnerStatus)
        const bIndex = priority.indexOf(b.winnerStatus)

        if (aIndex !== -1 && bIndex !== -1) {
          if (aIndex === bIndex) {
            // Statuses are same, so order by leName alphabetically
            return a.leName.localeCompare(b.leName)
          }
          // both statuses are in the priority list, compare their indices
          return aIndex - bIndex
        } else if (aIndex !== -1) {
          // only a's status is in the priority list, a comes first
          return -1
        } else if (bIndex !== -1) {
          // only b's status is in the priority list, b comes first
          return 1
        }

        // neither status is in the priority list, order by leNAme alphabetically
        return a.leName.localeCompare(b.leName)
      })
  }, [locationDrawings])

  useEffect(() => {
    if (locationWinners.length > 0) {
      setSelectedWinner({
        locationName: locationWinners[0].leName,
        licenseNumber: locationWinners[0].leLicenseNumber,
        winnerName: locationWinners[0].winnerName,
        sweepstakesWinner: locationWinners[0].dto,
        drawingSequence: locationWinners[0].drawingSequence ?? 0,
      })
    }
  }, [locationWinners])

  const grandPrizeWinnersColumns = getGrandPrizeWinnersColumns(
    setIsIndividualRedrawModalOpen
  )

  const locationWinnersColumns = getLocationWinnersColumns(
    locationWinners,
    handleCheckboxChange,
    handleSelectAllWinnerRows,
    selectedWinnerRows,
    winnerStatusFilter,
    setIsIndividualRedrawModalOpen,
    setIsViewRequestRedrawModalOpen,
    sweepstakeName ?? ''
  )

  if (winnerStatusFilter) {
    locationWinnersColumns.push(GRID_CHECKBOX_SELECTION_COL_DEF)
  }

  if (sweepstakeQuery.isPending) {
    return <ActivityIndicator />
  }

  if (sweepstakeQuery.isError || isNil(sweepstakeQuery.data)) {
    return <Box>An error occurred fetching the sweepstake.</Box>
  }

  const sweepstake = sweepstakeQuery.data
  const tierSortOrder: Record<string, number> = {
    VIP: 0,
    Elite: 1,
    Insider: 2,
    Member: 3,
  }

  const addNoWinnerRows = (
    gpWinners: GrandPrizeWinner[]
  ): GrandPrizeWinner[] => {
    const result: GrandPrizeWinner[] = [...gpWinners]
    const patronTiers = ['VIP', 'Elite', 'Insider', 'Member']
    const numGrandPrizeWinnersPerTier =
      sweepstakeQuery.data.sweepstakeData?.numGrandPrizeWinnersPerTier ?? 1
    patronTiers.forEach((tier) => {
      const gpWinnersByTier = gpWinners.filter(
        (winner) => winner.tierName === tier
      )
      for (
        let i = 0;
        i < numGrandPrizeWinnersPerTier - gpWinnersByTier.length;
        i++
      ) {
        result.push({
          id: `no-winner-${tier}-${i}`,
          winnerName: '-',
          patronId: -1,
          tierName: tier,
          winnerStatus: 'NoWinner',
          sweepstakeDrawingId: -1,
          contestEntryId: -1,
          drawnDate: '-',
          drawingSequence: 0,
        })
      }
    })
    return result
  }
  const sortedGrandPrizeRows = addNoWinnerRows([...grandPrizeWinners]).sort(
    (a, b) => {
      return tierSortOrder[a.tierName] - tierSortOrder[b.tierName]
    }
  )

  return (
    <SweepstakesDetailsHeader
      sweepstake={sweepstake}
      currentTab={`/Contests/Sweepstakes/${sweepstake.id}/Winners`}
      actionButton={
        <Box>
          <ActivityButton
            variant="contained"
            onClick={
              grandPrizeWinners ? toggleRedrawWinnersModalIsOpen : drawWinners
            }
            active={drawSweepstakesWinnersMutation.isPending}
          >
            {grandPrizeWinners ? 'Redraw Winners' : 'Draw Winners'}
          </ActivityButton>
          {(sweepstake.sweepstakeData?.state === 'InternalValidation' ||
            sweepstake.sweepstakeData?.state === 'WinnerValidation' ||
            sweepstake.sweepstakeData?.state === 'Closed') && (
            <Button
              sx={{ marginLeft: '15px' }}
              variant="contained"
              onClick={toggleNotifyWinnersModalIsOpen}
              disabled={
                sweepstake.sweepstakeData?.state === 'WinnerValidation' ||
                sweepstake.sweepstakeData?.state === 'Closed'
              }
            >
              {sweepstake.sweepstakeData?.state === 'WinnerValidation' ||
              sweepstake.sweepstakeData?.state === 'Closed'
                ? 'Locations Notified'
                : 'Notify Locations of Winners'}
            </Button>
          )}
        </Box>
      }
    >
      <Box>
        <Typography
          variant="h2"
          sx={{
            marginBottom: 3,
          }}
        >
          Grand Prize Winners
        </Typography>
        <DataTable
          columns={grandPrizeWinnersColumns}
          rows={sortedGrandPrizeRows}
          columnVisibilityModel={{ drawingSequence: false }} // hide drawing sequence column
          emptyDataImageSource={noLocationFound}
          noDataMessage="After the sweepstake ends and winners are drawn, grand prize winners will be posted here."
          onRowClick={handleRowClick}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 3,
            marginTop: 6,
          }}
        >
          <Typography variant="h2">Location Winners</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {winnerStatusFilter && (
              <Box sx={{ display: 'flex', mr: '10px', alignItems: 'center' }}>
                <Button color="primary" onClick={removeWinnerStatusFilter}>
                  <Typography
                    sx={{ mr: '3px' }}
                    variant="label-CTA"
                    color="primary"
                  >
                    {statusTextMap[winnerStatusFilter]}
                  </Typography>
                  <Close sx={{ mb: '5px' }} />
                </Button>
                {selectedWinnerRows.length > 0 && (
                  <Typography
                    sx={{ ml: '10px', mr: '10px' }}
                    color="text.secondary"
                  >
                    {`${selectedWinnerRows.length} Selected`}
                  </Typography>
                )}
              </Box>
            )}
            {!winnerStatusFilter ? (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <FilterStatusList
                  buttonName="Filter"
                  listName="Winner Statuses"
                  statusFilter={winnerStatusFilter}
                  setStatusFilter={setWinnerStatusFilter}
                />
              </Box>
            ) : (
              <Box>
                <SweepstakesWinnerActionsMenu
                  selectedWinners={selectedWinnerRows}
                  clearSelectedWinners={() => setSelectedWinnerRows([])}
                  winnerStatusFilter={winnerStatusFilter}
                  sweepstakeName={sweepstakeName ?? ''}
                />
              </Box>
            )}
          </Box>
        </Box>
        <DataTable
          onRowClick={handleRowClick}
          columns={locationWinnersColumns}
          rows={locationWinners}
          emptyDataImageSource={noLocationFound}
          noDataMessage="After the sweepstake ends and winners are drawn, location winners will be posted here."
          getRowClassName={(params) =>
            !isNil(
              selectedWinnerRows.find(
                (winner) => winner.sweepstakesWinner.id === params.row.id
              )
            )
              ? `selected-Winner`
              : ''
          }
          filterModel={{
            items: [
              {
                field: 'status',
                operator: 'equals',
                value: winnerStatusFilter,
              },
            ],
          }}
          slots={{ noResultsOverlay: NoResultsOverlayCustom }}
        />
      </Box>
      <SweepstakesRequestRedrawModal redrawWinners={redrawWinners} />
      {selectedWinner && (
        <SweepstakesViewRedrawRequestModal
          locationName={selectedWinner.locationName}
          licenseNumber={selectedWinner.licenseNumber}
          winnerName={selectedWinner.winnerName}
          requestReason={selectedWinner.sweepstakesWinner.redrawReason ?? ''}
          isModalOpen={isViewRequestRedrawModalOpen}
          toggleIsOpen={toggleViewRequestRedrawModalIsOpen}
          onConfirm={(reason) => {
            redrawWinners(
              reason,
              selectedWinner.sweepstakesWinner.sweepstakeDrawingId
            )
            toggleViewRequestRedrawModalIsOpen()
          }}
          onDeny={() => {
            denyRedrawRequest()
            toggleViewRequestRedrawModalIsOpen()
          }}
          onCancel={toggleViewRequestRedrawModalIsOpen}
        />
      )}
      <SweepstakesConfirmationModal
        variant="Redraw"
        sweepstakeName={sweepstake.name}
        isModalOpen={isRedrawWinnersModalOpen}
        toggleIsOpen={toggleRedrawWinnersModalIsOpen}
        onConfirm={() => {
          toggleRedrawWinnersModalIsOpen()
          drawWinners()
        }}
        onCancel={toggleRedrawWinnersModalIsOpen}
      />
      <SweepstakesConfirmationModal
        variant="Notify"
        sweepstakeName={sweepstake.name}
        isModalOpen={isNotifyWinnersModalOpen}
        toggleIsOpen={toggleNotifyWinnersModalIsOpen}
        onConfirm={() => {
          toggleNotifyWinnersModalIsOpen()
          notifyLocations()
        }}
        onCancel={toggleNotifyWinnersModalIsOpen}
      />
    </SweepstakesDetailsHeader>
  )
}
